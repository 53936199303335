import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from '@/components/useTranslation';
import { useRouter } from "next/router";
import { MyContext } from "@/pages/_app";


function ContactForm() {
  const [loading, setLoading] = useState(false);
  const t = useTranslation();
  const { register, handleSubmit, formState: { errors }, setValue,getValues  } = useForm();
  const router = useRouter();
  const [page, setPage] = useState('');
  const [isPackageSelected, setIsPackageSelected] = useState(false);


  const { globalValue, setGlobalValue } = useContext(MyContext);
  const { isSelected, setIsSelected } = useContext(MyContext);
  const { selectedValue, setSelectedValue } = useContext(MyContext);
  const { priceLöschen, setpriceLöschen } = useContext(MyContext);

  const [selectedOption, setSelectedOption] = useState("");

  const deleteOptionValue = `${t.clear2} ${t.basic} - ${t.loschung1} ${globalValue}`;

 
  // select kısmı default gelmesi için kısım  önemli SİLMEE
  useEffect(() => {
    // Code to initialize selectedValue with the correct value
    const initialValue = ''; // Replace this with the initial value you want
    setSelectedValue(initialValue);
  }, []);

  useEffect(() => {
    const selectElement = document.getElementById("selectOption");
    const optionElements = selectElement.getElementsByTagName("option");
    
    for (let i = 0; i < optionElements.length; i++) {
      optionElements[i].removeAttribute('selected');
      if (optionElements[i].value === selectedValue) {
        setSelectedOption(optionElements[i].value);
        optionElements[i].setAttribute('selected', 'selected');
      }
    }
    },[selectedValue]);

  useEffect(() => {
    if (isSelected) {
      console.log("içerde")
      setValue("package_id", 99);
      setValue("quantity", parseInt(globalValue));
      setValue("price", parseInt(priceLöschen));
    }
    
  }, [isSelected]);


  useEffect(() => {
    setValue("package_id", selectedValue);
    setIsPackageSelected(true);
  }, [selectedValue]);

  const onSubmit = (data) => {

    if (!data.package_id && !setIsPackageSelected) {
      toast.error(`${t.package_id_required}`, {
        position: "bottom-right",
        theme: "light",
        draggable: true,
        style: {
          borderRadius: "10px",
        },
      });
      return;
    }
      if(getValues("package_id")==99){
        data.price=parseInt(priceLöschen);
        data.quantity=parseInt(globalValue);
      }

    setIsPackageSelected(false);
    setLoading(true);
    // setPage(localStorage.getItem("page"));
    axios
      .post("https://api.yaskagroup.com/public/api/contacts", {
        ...data,
        // page: localStorage.getItem("page")
      })
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
          toast.success(`${t.order_complete}`, {
            position: "bottom-right",
            theme: "light",
            draggable: true,
            style: {
              borderRadius: "10px",
            },
          });
          
        }, 1000);
        setValue("first_name", "");
          setValue("email", "");
          setValue("phone", "");
          setValue("note", "");
          setValue("package_id", "");
          setValue("page", "");
          setValue("quantity", null);
          setValue("price", null);
          setSelectedOption("");
          setIsSelected(false);
          setGlobalValue(1);
        
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          setLoading(false);
          toast.error(`${t.order_not_complete}`, {
            position: "bottom-right",
            theme: "light",
            draggable: true,
            style: {
              borderRadius: "10px",
            },
          });
          
        }, 500);
      });
  };


  return (
    <div className="w-full form-content" id="kontakt">
      <ToastContainer />
      <div
        className="p-8 bg-white rounded-3xl lg:col-span-3 lg:p-12"
        style={{
          boxShadow: "0px 5px 91.91px 9.09px rgba(29, 59, 78, 0.06)",
        }}>
        <h3 className="pb-6 text-3xl font-bold text-gray-900">{t.contact}</h3>
        <form action="true" className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="website_id" value={9}
            {...register("website_id", 
              { required: true,}
            )}
          ></input>

          <input
            type="hidden"
            name="quantity"
            // value={globalValue}
            {...register("quantity")}
          />
          <input
            type="hidden"
            name="price"
            // value={priceLöschen}
            {...register("price")}
          />
          <select
              id="selectOption"
              className={`${errors.package_id ? "!border-red-500" : ""}`}
              {...register("package_id", {
                required: true,
              })}
          >
            <option value="" >{t.select_package}</option>
            <option value="85">Google {t.basic} - 25 {t.reviews}</option>
            <option value="86">Google {t.business} - 50 {t.reviews}</option>
            <option value="88">Google {t.pro} - 100 {t.reviews}</option>
            <option value="92">Google Local Guide {t.basic} - 25 {t.reviews}</option>
            <option value="94">Google Local Guide {t.business} - 50 {t.reviews}</option>
            <option value="95">Google Local Guide {t.pro} - 100 {t.reviews}</option>
            <option value="400">Trustpilot {t.basic} - 25 {t.reviews}</option>
            <option value="401">Trustpilot {t.business} - 50 {t.reviews}</option>
            <option value="402">Trustpilot {t.pro} - 100 {t.reviews}</option>
            <option value="403">{t.employer} {t.basic} - 25 {t.reviews}</option>
            <option value="404">{t.employer} {t.business} - 50 {t.reviews}</option>
            <option value="405">{t.employer} {t.pro} - 100 {t.reviews}</option>
            <option value="407">{t.wunschplattform} {t.basic} - 25 {t.reviews}</option>
            <option value="408">{t.wunschplattform} {t.business} - 50 {t.reviews}</option>
            <option value="409">{t.wunschplattform} {t.pro} - 100 {t.reviews}</option>
            <option value="99">Google {t.clear2} {t.basic} - {t.loschung1} {globalValue} {globalValue == '1' ? t.review : t.reviews} </option>
            
          </select>

          
          {errors.package_id && errors.package_id.type === "required" && (
                <span className="text-sm text-red-500">{t.package_id_required}</span>
              )}

          <div>
            <label className="sr-only" htmlFor="name">
              {t.name_surname}
            </label>
            <input
              placeholder={t.name_surname}
              type="text"
              id="first_name"
              {...register("first_name")}
            />
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className="sr-only" htmlFor="email">
                {t.email}
              </label>
              <input
                className={` ${errors.email ? "!border-red-500" : ""
                  }`}
                placeholder={t.email}
                type="email"
                id="email"
                {...register("email", {
                  required: true,
                  pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <span className="!text-red-500 text-sm">{t.email_required}</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="!text-red-500 text-sm">
                  {t.email_invalid}
                </span>
              )}
            </div>
            <div>
              <label className="sr-only" htmlFor="phone">
                {t.phone}
              </label>
              <input
                className={`${errors.phone ? "!border-red-500" : ""
                  }`}
                placeholder={t.phone}
                type="tel"
                id="phone"
                onKeyPress={(e) => {
                  if (e.key.length === 1 && !/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                {...register("phone", {
                  required: true,
                })}
              />

              {errors.phone && errors.phone.type === "required" && (
                <span className="!text-red-500 text-sm">{t.phone_required}</span>
              )}
              {errors.phone && errors.phone.type === "pattern" && (
                <span className="!text-red-500 text-sm">
                  {t.phone_invalid}
                </span>
              )}
            </div>
          </div>

          <div>
            <label className="sr-only" htmlFor="message">
              Message
            </label>
            <textarea
              placeholder={t.message}
              rows={8}
              id="note"
              {...register("note")}
            />
          </div>
          <select
              id="selectOption"
              className={`${errors.page ? "!border-red-500" : ""}`}
              {...register("page", {
                required: true,
              })}
          >
            <option value="">Comment nous avez-vous découverts ?</option>
            <option value="Google">Google</option>
            <option value="Mail">Bulletin d'information par e-mail</option>
            <option value="Empfehlung">Recommandation</option>
            <option value="Bestandskunde">Client existant</option>
            <option value="Social media">Médias sociaux</option>
            
          </select>

          <div className="mt-4">
            {loading ? (
              <button
                className="inline-block w-full px-5 py-3 font-medium text-white bg-blue-700 rounded-lg sm:w-auto"
                disabled
              >
                {t.loading}...
              </button>
            ) : (
              <button
                className="inline-block w-full px-5 py-3 font-medium text-white bg-blue-700 rounded-lg sm:w-auto"
                type="submit"
              >
                {t.button2}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;