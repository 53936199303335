import { useRouter } from 'next/router';
import de from '../../locales/de/language';
import fr from '../../locales/fr/language';

export function useTranslation() {
    const router = useRouter();
    const { locale } = router;

    let translations;

    switch (locale) {
        case 'de':
            translations = de;
            break;
        case 'fr':
            translations = fr;
            break;
        default:
            translations = fr;
    }

    return translations;
}