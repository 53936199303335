export default {

  // Global
  "lang": "de",
  "home": "Startseite",
  "services": "Leistungen",
  "process": "Prozess",
  "price": "Preise",
  "faq": "FAQ",
  "contact": "Jetzt Anfragen",
  "button": "Jetzt Buchen",
  "button2": "Anfragen senden",
  "loading": "Laden",
  "reviews": "Bewertungen",
  "review": "Bewertung",
  "clear": "Loschen",
  "clear2": "Löschung",
  "agb": "AGB",
  "privacy_policy": "Datenschutzerklärung",
  "legal": "Rechtliches",
  "impressum": "Impressum",
  "name_surname": "Name",
  "buy_reviews": "Bewertungen Kaufen",
  "visit_us": "Besuchen Sie uns, um die Details zu sehen",
  "buy_clear": "Bewertungen Löschen",
  "copyright": "Copyright seit ©2011 Alle Rechte vorbehalten",
  "address": "Potsdamer Straße 144 10783 Berlin Deutschland",
  "slogan": "Wir retten Ihren Ruf!",
  "email_required": "E-Mail ist erforderlich",
  "email_invalid": "Ungültiges E-Mail-Format",
  "phone_required": "Telefon ist erforderlich",
  "package_id_required": "Paket ist erforderlich",
  "phone_invalid": "Ungültiges Rufnummernformat",
  "phone": "Telefonnummer",
  "order_complete": "Ihre Nachricht wurde erhalten",
  "order_not_complete": "Wir konnten Ihre Nachricht nicht erhalten",
  "message": "Sonstiges",
  "email": "E-Mail",
  "kdv": "inkl. MwSt.",
  "positive": "Positive",
  "negative": "Negative",
  "garantie": "Zufriedenheitsgarantie",
  "loschungData": "für jede gelöschte <br/> Bewertung / Stückpreis",
  "select_package": "Welches Paket interessiert Sie?",
  "basic": "Einsteiger",
  "business": "Business",
  "pro": "Pro",
  "loschung1": "Bis",
  "loschung2": "Ab",

  "url_google": "google-bewertungen-kaufen",
  "url_google_local_guide": "google-local-guide-bewertungen-kaufen",
  "url_trustpilot": "trustpilot-bewertungen-kaufen",
  "url_arbeitgeber": "arbeitgeber-bewertungen-kaufen",
  "url_google_clear": "google-bewertungen-loschen",
  "url_wunschplattform": "wunschplattform-bewertungen-kaufen",

  "employer": "Arbeitgeber",
  "wunschplattform": "Wunschplattform",


  // Home Hero
  "home_hero_title": "<h1>Google Bewertungen <br/> Professionell, Diskret <br/> Authentisch.</h1>",
  "home_hero_description": "Das Sprungbrett für deinen Ruf und Erfolg! Vertraue der Macht der Meinungen!",
  "home_hero_list_item1": "Maßgeschneiderte Inhalte",
  "home_hero_list_item2": "Persönliches Intervall",
  "home_hero_list_item3": "1 Jahr Garantie",
  "home_hero_button": "Preise prüfen",

  "rating": "Mit 35.000 betreuten Unternehmen und einer Zufriedenheitsquote von 98% haben wir bewiesen, dass Kundenzufriedenheit unser oberstes Ziel ist.",

  // Home Step 
  "home_step1_title": "Echte Nutzer, keine Roboter.",
  "home_step1_description": "Es ist von entscheidender Bedeutung,  Bewertungen von echten Nutzern zu erhalten und sich von programmgesteuerten Bot Bewertungen fernzuhalten. Bei uns erhalten Sie nur Bewertungen von verifizierten lokalen Nutzern.",
  "home_step1_list_item1": "Über 50.000 Rezensenten in ganz Deutschland, Österreich und der Schweiz",
  "home_step1_list_item2": "Deutsche Namen, verifizierte Accounts",
  "home_step1_list_item3": "Natürliche Profilfotos und Historien",
  "home_step2_title": "Google Bewertungen",
  "home_step2_description": "<p> Die Bedeutung von Google-Bewertungen liegt darin, dass sie das Vertrauen und die Entscheidungsfindung von Kunden maßgeblich beeinflussen.<br/> <br/> Sie geben Kunden die Möglichkeit, die Erfahrungen anderer Menschen zu sehen und daraus Rückschlüsse auf die Kundenzufriedenheit und den Ruf eines Unternehmens zu ziehen. <br/> <br/> Positive Bewertungen können das Vertrauen und die Bereitschaft zum Kauf erhöhen, während negative Bewertungen potenzielle Kunden abschrecken können. Google-Bewertungen haben somit einen direkten Einfluss auf den Erfolg und die Reputation eines Unternehmens im digitalen Zeitalter. </p>",
  "home_step3_title": "Warum sollten Sie Bewertungen kaufen?",
  "home_step3_description": "<p>Laut einer Umfrage von BrightLocal geben 86% der Verbraucher an, dass sie Online-Bewertungen genauso vertrauen wie persönlichen Empfehlungen. <br/> <br/> Eine Studie von Harvard Business School ergab, dass eine Steigerung der Bewertung um einen Stern zu einer durchschnittlichen Umsatzsteigerung von 5-9% führen kann. <br/> <br/> Laut einer Studie von Moz hat die Anzahl und Qualität der Bewertungen einen direkten Einfluss auf das Ranking eines Unternehmens in den lokalen Suchergebnissen. <br/> <br/> Eine Umfrage von ReviewTrackers zeigt, dass 94% der Verbraucher negative Bewertungen lesen, bevor sie eine Kaufentscheidung treffen. Das unterstreicht die Wichtigkeit, negative Bewertungen ernst zu nehmen und darauf zu reagieren. </p>",

  // Price
  "price_description": "Kein Abo, nur eine einmalige Buchung - flexibel und unkompliziert zu deinem gewünschten Service.",
  "price_total": "Gesamtsumme",

  // Services
  "services_title": "Wie ist der Ablauf der Veröffentlichung einer positiven Bewertung?",
  "services_item1_title": "Wählen Sie das Paket, das zu Ihnen passt",
  "services_item1_description": "Wählen Sie aus den plattformspezifischen Paketen das für Sie passende aus",
  "services_item2_title": "Bestimmen Sie die Details Ihrer Kampagne",
  "services_item2_description": "Unsere Kundenbetreuer werden sich mit Ihnen in Verbindung setzen und mit Ihnen die Kampagne besprechen.",
  "services_item3_title": "Textinhalte",
  "services_item3_description": "Stellen Sie uns Ihre eigenen Texte bereit oder überlassen Sie uns die Texterstellung. Natürlich passend zu Ihrem Unternehmen.",
  "services_item4_title": "Fotos in den Bewertungen",
  "services_item4_description": "Auf Wunsch können wir einige Bewertungen mit Fotos abgeben. Diese müssen Sie uns bereitstellen.",
  "services_item5_title": "Start der Vergabe",
  "services_item5_description": "Sobald die Vorkehrungen getroffen sind, beginnen wir mit der Vergabe. Sie müssen sich um nichts mehr kümmern.",
  "services_item6_title": "Berichterstattung",
  "services_item6_description": "Nach Abschluss der Vergabe erhalten Sie einen detaillierten Bericht zu den abgegebenen Bewertungen. ",

  // Platform

  "platform_title": "Wir sind nicht nur Google Experten",

  // FAQ
  "faq_title": "Häufig gestellte Fragen",
  "question_1": "Kann man Google Bewertungen kaufen?",
  "answer_1": "Funfsterne.de ist der Marktführer im Reputationsmanagement und bietet seit <strong>2011</strong> professionelle und qualitativ hochwertige Bewertungen.",
  "question_2": "Was kostet eine Google Bewertung?",
  "answer_2": "<strong>Positive Google Bewertungen</strong> sind die einfachste und günstigste Methode um neue Kunden zu gewinnen und den Umsatz deutlich zu steigern. Allein für <strong>500,00€</strong> erhalten Sie <strong>25</strong> qualitativ hochwertige <strong>Google Bewertungen</strong>.",
  "question_3": "Kann man schlechte Google Bewertungen kaufen?",
  "answer_3": "Bei Funfsterne können Sie ausschließlich positive Bewertungen die Löschung von negativen Bewertungen kaufen. <strong>Negative Bewertungen</strong> bieten wir nicht an.",
  "question_4": "Was kostet es eine Google Bewertung zu löschen?",
  "answer_4": "Die Löschung einer negativen Bewertung beginnt ab <strong>175,00€</strong>.",
  "question_5": "Warum löscht Google positive Bewertungen?",
  "answer_5": "Google kann positive Bewertungen löschen, wenn sie gegen ihre Richtlinien verstoßen. Deshalb müssen diese Google konform abgegeben werden. Zudem können Google Algorithmen programmgesteuerte Bewertungen entdecken. Aus diesem Grund ist es sehr wichtig Bewertungen von professionellen Anbietern und deren verifizierten Nutzern zu erhalten.",
  "question_6": "Wie aussagekräftig sind Google Bewertungen?",
  "answer_6": "<strong>Google-Bewertungen</strong> können eine wertvolle Informationsquelle sein, um sich ein Bild von einem Unternehmen, Produkt oder einer Dienstleistung zu machen. Hier sind einige Faktoren, die die Aussagekraft von Google-Bewertungen beeinflussen können:\n" +
      "<br/> <br/>" +
      "1 - Anzahl der Bewertungen: Eine höhere Anzahl an Bewertungen gibt in der Regel ein besseres Gesamtbild wieder und reduziert das Risiko von Einzelmeinungen, die möglicherweise nicht repräsentativ sind.\n" +
      "<br/> <br/>" +
      "2 - Qualität der Bewertungen: Es ist ratsam, sich nicht nur auf die Sternebewertung zu konzentrieren. Detaillierte und ausführliche Bewertungen bieten einen tieferen Einblick und können mehr Vertrauen schaffen.\n" +
      "<br/> <br/>" +
      "3 - Diversität der Bewertungen: Es ist hilfreich, Bewertungen von verschiedenen Personen mit unterschiedlichen Perspektiven zu betrachten, um ein ausgewogenes Bild zu erhalten.\n" +
      "<br/> <br/>" +
      "4 - Aktualität der Bewertungen: Es ist wichtig zu beachten, dass sich die Leistung eines Unternehmens im Laufe der Zeit ändern kann. Daher sollten aktuellere Bewertungen mehr Gewicht erhalten als ältere Bewertungen.",
  "question_7": "Kann man Google selbst bewerten?",
  "answer_7": "Selbstverständlich können Sie auch als Google Nutzer eine Bewertung abgeben, jedoch darf das bewertete Profil nicht Ihr eigenes sein.",

  // Process
  "process_hero_title": "Kaufen Kommentar <br /> Jetzt nehmen <br /> Sehr einfach.",
  "process_hero_description": "Wir verfügen über ein hervorragendes Expertenteam, das qualitativ hochwertige Dienste erstellt und unsere Kunden auch unterwegs betreut. Wir bieten den-Bewertungsdienst seit den Anfängen an, als der Online-Handel begann, zum primären Ort des Marketings zu werden.",

  "process_step_1_title": "Google Bewertungen",
  "process_step_1_description": "Die Suchmaschine von Google steigert das Potenzial der Unternehmen, indem sie es ihnen ermöglicht, an der Spitze der Liste angezeigt zu werden. <br/> <br/> Diejenigen, die das Navigationssystem nutzen, berücksichtigen also die Unternehmen, die sie in den oberen Rängen besuchen.",

  "process_step_2_title": "Warum sollte ich Google Maps-Bewertungen kaufen?",
  "process_step_2_description": "Die Anzahl der Kommentare, die ein Unternehmen erhält, ist für Besucher immer wichtig. Unternehmen mit wenigen Kommentaren und wenigen Sternen werden von den Besuchern weniger bevorzugt, weil sie kein Vertrauen erwecken. Aus diesem Grund ist der Google Maps-Kommentardienst eines Ihrer Must-haves.",

  "process_step_3_title": "Google Bewertungen",
  "process_step_3_description": "Die Suchmaschine von Google steigert das Potenzial der Unternehmen, indem sie es ihnen ermöglicht, an der Spitze der Liste angezeigt zu werden. <br/> <br/> Diejenigen, die das Navigationssystem nutzen, berücksichtigen also die Unternehmen, die sie in den oberen Rängen besuchen.",

  "process_step_4_title": "Warum sollte ich Google Maps-Bewertungen kaufen?",
  "process_step_4_description": "Die Anzahl der Kommentare, die ein Unternehmen erhält, ist für Besucher immer wichtig. Unternehmen mit wenigen Kommentaren und wenigen Sternen werden von den Besuchern weniger bevorzugt, weil sie kein Vertrauen erwecken. Aus diesem Grund ist der Google Maps-Kommentardienst eines Ihrer Must-haves.",

  "process_services_title": " Wie funktioniert der Prozess der Veröffentlichung eines positiven Kommentars?",
  "process_services_description_1": "<p> Der schnellste und effektivste Weg, um sich von Konkurrenten hervorzuheben, ist durch positive Kundenbewertungen mehr Sichtbarkeit zu erhalten.</p><br/><p>Laut aktuellen Studien entscheiden 86% aller Kunden für das Unternehmen mit den meisten Rezensionen und dem besseren Bewertungsdurchschnitt in den Suchergebnissen. <br/> Der schnellste und effektivste Weg, um sich von Konkurrenten hervorzuheben, ist durch positive Kundenbewertungen mehr Sichtbarkeit zu erhalten.</p><br/><p>Laut aktuellen Studien entscheiden 86% aller Kunden für das Unternehmen mit den meisten Rezensionen und dem besseren Bewertungsdurchschnitt in den Suchergebnissen.</p>",
  "process_services_description_2": "<p>Der schnellste und effektivste Weg, um sich von Konkurrenten hervorzuheben, ist durch positive Kundenbewertungen mehr Sichtbarkeit zu erhalten.</p><br/><p> Laut aktuellen Studien entscheiden 86% aller Kunden für das Unternehmen mit den meisten Rezensionen und dem besseren Bewertungsdurchschnitt in den Suchergebnissen. <br/>Der schnellste und effektivste Weg, um sich von Konkurrenten hervorzuheben, ist durch positive Kundenbewertungen mehr Sichtbarkeit zu erhalten.</p><br/><p>Laut aktuellen Studien entscheiden 86% aller Kunden für das Unternehmen mit den meisten Rezensionen und dem besseren Bewertungsdurchschnitt in den Suchergebnissen.</p>",


  // Dynamic Page

  "dynamic_page_hero_description": "Positive @@burasidegisecek@@ Bewertungen sind sehr wichtig, insbesondere für Unternehmen, die online präsent sind. @@burasidegisecek@@ berücksichtigt Bewertungen und Rezensionen als Ranking-Faktoren für lokale Suchergebnisse. Unternehmen mit vielen positiven Bewertungen haben daher eine bessere Chance, in den Suchergebnissen höher platziert zu werden. Dies kann zu einer höheren Sichtbarkeit führen und potenziell mehr Kunden anziehen.",

  "dynamic_page_step1_title": "Warum sind @@burasidegisecek@@ Bewertungen wichtig?",
  "dynamic_page_step1_description": "<p>Die Bedeutung von @@burasidegisecek@@ Bewertungen liegt darin, dass sie das Vertrauen und die Entscheidungsfindung von Kunden maßgeblich beeinflussen.<br/> <br/>Sie geben Kunden die Möglichkeit, die Erfahrungen anderer Menschen zu sehen und daraus Rückschlüsse auf die Kundenzufriedenheit und den Ruf eines Unternehmens zu ziehen.<br/><br/>Positive Bewertungen können das Vertrauen und die Bereitschaft zum Kauf erhöhen, während negative Bewertungen potenzielle Kunden abschrecken können. @@burasidegisecek@@ Bewertungen haben somit einen direkten Einfluss auf den Erfolg und die Reputation eines Unternehmens im digitalen Zeitalter.</p>",
  "dynamic_page_step2_title": "Wie können Sie Ihre @@burasidegisecek@@ Bewertungen verbessern?",
  "dynamic_page_step2_description": "Es gibt mehrere Möglichkeiten, Ihre Google-Bewertungen zu verbessern. Eine Möglichkeit besteht darin, Ihre Kunden dazu zu ermutigen, Bewertungen abzugeben und sicherzustellen, dass diese Bewertungen positiv sind. Sie können auch professionelle Google-Bewertungsmanagementdienste in Anspruch nehmen, um Ihren Online-Ruf zu verbessern und Ihre Marke zu stärken.",

  "dynamic_page_step3_title": "Wie sehen die Bewertungen aus",
  "dynamic_page_step3_description": "Wir bieten eine breite Auswahl von über 50.000 Rezensenten an, um sicherzustellen, dass Sie die passenden Bewertungen für Ihre Dienstleistungen oder Produkte erhalten. Sie haben die Möglichkeit, zu entscheiden, ob Sie Bewertungen von männlichen oder weiblichen Rezensenten, von jungen oder älteren Personen erhalten möchten. Des Weiteren erhalten Sie auch nur Bewertungen die vom Textinhalt zu Ihren Dienstleistungen oder Produkten passen. Auf diese Weise wird sichergestellt, dass die Bewertungen inhaltlich zu Ihrem Unternehmen passen und einen umfassenden Einblick in die Kundenerfahrungen bieten.",

  // Löschung Dynamic 
  "dynamic_page_loeschung_hero_title": "Google Bewertungen Löschen",
  "dynamic_page_loeschung_hero_description": "Es ist zwar wünschenswert, negative Google-Bewertungen zu vermeiden, jedoch ist es in der Praxis leider nicht immer möglich, sie komplett zu vermeiden. Negative Bewertungen können den Ruf eines Unternehmens beeinträchtigen. Potenzielle Kunden könnten abgeschreckt werden und sich für Konkurrenzunternehmen entscheiden, die eine bessere Bewertungen haben. Ein schlechter Ruf kann sich negativ auf das Image und den Erfolg eines Unternehmens auswirken, denn viele Menschen lesen Bewertungen, bevor sie eine Kaufentscheidung treffen.",
  "dynamic_page_loeschung_step1_title": "Wie können Sie negative Bewertungen loswerden?",
  "dynamic_page_loeschung_step1_description": "Wenn negative Bewertungen häufig auftreten und nicht angemessen angegangen werden, kann dies zu einem Verlust von Kunden führen. Mit uns an Ihrer Seite brauchen Sie sich keine Sorgen mehr um Schmähkritik oder wettbewerbsbedingte negative Bewertungen zu machen. Wir helfen Ihnen dabei, diese Bewertungen zu entfernen.",
  "dynamic_page_loeschung_step2_title": "Können negative Bewertungen entfernt werden?",
  "dynamic_page_loeschung_step2_description": "Definitiv! Mit der richtigen Unterstützung können negative Bewertungen entfernt werden. Wir kooperieren diesbezüglich mit einer sehr erfolgreichen Anwaltskanzlei und verfügen über Sonderkonditionen. Demnach zahlen Sie nur am Ende der Leistung und nur für erfolgreich gelöschte Bewertungen. Unsere Erfolgsquote liegt bei ca. 97%!",


  "data": [
    {
      "Google": [
        {
          "title": "Google Einsteiger",
          "proBewertung": "25",
          "value": "120",
          "price": "500",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte"
          ]
        },
        {
          "title": "Google Business",
          "proBewertung": "50",
          "value": "121",
          "price": "900",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard"
          ]
        },
        {
          "title": "Google Pro",
          "value": "123",
          "proBewertung": "100",
          "price": "1600",
          "features": [
            "10 Local Guide Bewertungen inklusivee",
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard",
            "Aufteilbar auf mehrere Profile / Standorte"
          ]
        }
      ],
      "Google Local Guide": [
        {
          "title": "Google Local Guide Einsteiger",
          "proBewertung": "25",
          "value": "129",
          "price": "750",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte"
          ]
        },
        {
          "title": "Google Local Guide Business",
          "proBewertung": "50",
          "value": "130",
          "price": "1350",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard"
          ]
        },
        {
          "title": "Google Local Guide Pro",
          "proBewertung": "100",
          "value": "131",
          "price": "2100",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard",
            "Aufteilbar auf mehrere Profile / Standorte"
          ]
        }
      ],
      "Trustpilot": [
        {
          "title": "Trustpilot Einsteiger",
          "proBewertung": "25",
          "value": "133",
          "price": "650",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte"
          ]
        },
        {
          "title": "Trustpilot Business",
          "proBewertung": "50",
          "value": "134",
          "price": "1200",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard"
          ]
        },
        {
          "title": "Trustpilot Pro",
          "proBewertung": "100",
          "value": "136",
          "price": "1950",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard",
            "Aufteilbar auf mehrere Profile / Standorte"
          ]
        }
      ],
      "Arbeitgeber": [
        {
          "title": "Arbeitgeber Einsteiger",
          "proBewertung": "25",
          "value": "153",
          "price": "600",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte"
          ]
        },
        {
          "title": "Arbeitgeber Business",
          "proBewertung": "50",
          "value": "155",
          "price": "1100",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard"
          ]
        },
        {
          "title": "Arbeitgeber Pro",
          "proBewertung": "100",
          "value": "158",
          "price": "1800",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard",
            "Aufteilbar auf mehrere Profile / Standorte"
          ]
        }
      ],
      "Google Loschen": [
        {
          "title": "Google Löschung Business",
          "titleleft":"Wie viele negative Einträge möchten Sie entfernen lassen?",
          "leftdesc1":"Wir helfen Ihnen ungewollte und nicht zutreffende Bewertungen einfach und schnell zu entfernen, denn Schmähkritiken müssen Sie nicht dulden!",
          "leftdesc2":"Ohne Kostenrisiko - nur Zahlung bei Erfolg!",
          "rangeTitle":"Zahl der zu löschenden Bewertungen",
          "rangeText":"Sie sparen",
          "name":"Bewertungen",
          "garantie":"Zufriedenheitsgarantie",
          "proBwt":"pro gelöschte Bewertung",
          "proBewertung": "6",
          "loschungData3": "Ab",
          "price": "185",
          "value": "230",
          "features": [
            "Deutschland - Österreich -  Schweiz ",
            "Zahlung am Ende der Leistung.",
            "Zahlung nur für gelöschte Bewertungen",
            "97% Löschrate",
            "Kein Kostenrisiko."
          ]
        },
      ],
      "Wunschplattform": [
        {
          "title": "Wunschplattform Einsteiger",
          "proBewertung": "25",
          "value": "161",
          "price": "650",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte"
          ]
        },
        {
          "title": "Wunschplattform Business",
          "proBewertung": "50",
          "value": "163",
          "price": "1200",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard"
          ]
        },
        {
          "title": "Wunschplattform Pro",
          "proBewertung": "100",
          "value": "165",
          "price": "1950",
          "features": [
            "Verifizierte lokale Nutzer",
            "Maßgeschneiderter Turnus",
            "Maßgeschneiderte Texte",
            "Persönliches Dashboard",
            "Aufteilbar auf mehrere Profile / Standorte"
          ]
        }
      ],
    }
  ],

  // Impressum
  "impressum_step1_title": "Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:",
  "impressum_step1_description": "Funfsterne , Anschrift wie oben",
  "impressum_step2_title": "EU-Streitschlichtung",
  "impressum_step2_description": "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr unsere E-Mail-Adresse finden Sie oben im Impressum.",
  "impressum_step3_title": "Verbraucher­streit­beilegung / Universal­schlichtungs­stelle",
  "impressum_step3_description": "Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
  "impressum_step4_title": "Haftung für Inhalte",
  "impressum_step4_description": "Der Seitenbetreiber übernimmt keine Haftung für von Dritten übermittelte oder gespeicherte Informationen. Eine Pflicht zur Überwachung dieser Informationen besteht seitens des Seitenbetreibers nicht. Sofern rechtswidrige Informationen von Dritten übermittelt oder gespeichert werden, haftet der Seitenbetreiber erst, wenn er einer berechtigten Aufforderung zur Löschung der Informationen nicht nachkommt. Offensichtlich rechtswidrige Informationen werden umgehend nach Bekanntwerden gelöscht.",
  "impressum_step5_title": "Haftung für Links",
  "impressum_step5_description": "Der Seitenbetreiber hat keinen Einfluss auf externe Links. Vor Veröffentlichung der Links auf diesen Seiten wurden diese im zumutbaren Rahmen auf Übereinstimmung mit den gesetzlichen Bestimmungen geprüft. Der Seitenbetreiber ist nicht verantwortlich für Änderungen, die sich auf den über externen Links aufrufbaren Seiten ergeben. Insbesondere ist eine durchgehende Kontrolle von externen Links auf Rechtsverletzungen nicht zumutbar. Sofern der Seitenbetreiber auf rechtswidrige Inhalte hingewiesen wird, die über externe Links abrufbar sind, entfernt er diese Links umgehend.",
  "impressum_step6_title": "Urheberrecht",
  "impressum_step6_description": "Das Urheberrecht an allen auf den Seiten des Betreibers abrufbaren Texten und multimedialen Inhalten liegt beim Seitenbetreiber, soweit abweichende Urheberrechte nicht gesondert ausgewiesen werden. Sofern die Urheberrechte Dritter nicht ausgewiesen sind, wird um eine Mitteilung via E-Mail gebeten. Der Seitenbetreiber wird solche Inhalte umgehend entfernen. Die Verarbeitung, Verbreitung und Vervielfältigung von Inhalten ist ohne die ausdrückliche Zustimmung des Seitenbetreibers nicht zulässig.",

  // Datenschutz
  "datenschutz_title1": "Allgemeines",
  "datenschutz_description1": "Ihre personenbezogenen Daten im Sinne von Art. 4 Nr. 1 DSGVO (z.B. Anrede, Name, Anschrift, E-Mail-Adresse, Zahlungsinformationen) werden von uns nur gemäß den Bestimmungen des deutschen Datenschutzrechts und unter Berücksichtigung der europäischen Datenschutzgrundverordnung (DSGVO) verarbeitet. Die nachfolgenden Vorschriften informieren Sie über Art, Umfang und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten.",

  "datenschutz_description2": "Die Verarbeitung im Sinne von Art. 4 Nr. 2 DSGVO von personenbezogenen Daten ist gemäß Art. 6 DSGVO rechtmäßig, wenn eine der folgenden Voraussetzungen vorliegt:",

  "datenschutz_description_list": "<li>Die betroffene Person hat ihre Einwilligung zu der Verarbeitung der sie betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke gegeben;</li> <li>die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen;</li><li>die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt;</li><li>die Verarbeitung ist erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen;</li> <li>die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li> <li>die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt.</li>",

  "datenschutz_description3": "Die Verarbeitung besonderer personenbezogenen Daten (z.B. Gesundheitsdaten) im Sinne von Art. 9 Abs. 1 DSGVO ist insbesondere gemäß Art. 9 Abs. 2 DSGVO rechtmäßig, wenn eine der folgenden Voraussetzungen vorliegt:",

  "datenschutz_description4": "<li>Es liegt eine ausdrückliche Einwilligung der Person vor;</li><li>die Verarbeitung ist zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder bei Handlungen der Gerichte im Rahmen ihrer justiziellen Tätigkeit erforderlich.</li>",

  "datenschutz_description5": "Eine automatische Entscheidungsfindung oder ein Profiling bezüglich personenbezogener Daten im Sinne von Art. 22 DSGVO findet nicht statt.",

  "datenschutz_description6": "Der Betreiber stellt die Sicherheit der Daten gemäß Art. 32 DSGVO unter Berücksichtigung des Proportionalitätsgrundsatzes durch geeignete technische Maßnahmen sicher. Sollte es wider Erwarten zu einer Verletzung des Datenschutzes kommen, wird die zuständige Aufsichtsbehörde gemäß Art. 33 DSGVO, sowie die betroffene Person gemäß Art. 34 DSGVO benachrichtigt.",

  "datenschutz_title2": "Geltungsbereich",
  "datenschutz_description7": "Diese Datenschutzerklärung bezieht sich nur auf unsere Webseiten. Falls Sie über Links auf unseren Seiten auf andere Seiten weitergeleitet werden, informieren Sie sich bitte dort über den jeweiligen Umgang mit Ihren Daten.",

  "datenschutz_title3": "Cookies",
  "datenschutz_description8": "Diese Seite speichert Cookies, um ihre Besucher wieder zu erkennen. Es erfolgt keine Weitergabe der aufgrund von Cookies erlangten Daten an Dritte. Sofern Sie die Speicherung von Cookies dennoch unterbinden möchten, haben Sie die Möglichkeit dies in den Einstellungen Ihres Browsers zu deaktivieren.",

  "datenschutz_title4": "Google Webfonts",
  "datenschutz_decsription9": "Auf diesen Internetseiten werden externe Schriften, Google Fonts verwendet. Google Fonts ist ein Dienst der Google Inc. („Google“). Die Einbindung dieser Web Fonts erfolgt durch einen Serveraufruf, in der Regel ein Server von Google in den USA. Hierdurch wird an den Server übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird die IP-Adresse des Browsers des Endgerätes des Besuchers dieser Internetseiten von Google gespeichert.",
  "datenschutz_decsription10": "Nähere Informationen finden Sie in den Datenschutzhinweisen von Google, die Sie hier abrufen können:",

  "datenschutz_title5": "Google Analytics",
  "datenschutz_description11": " Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (“Google”). Google Analytics verwendet sog. “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.",

  "datenschutz_description12": "Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.",

  "datenschutz_description13": "Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; Es wird jedoch darauf hingewiesen, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.",
  "datenschutz_description14": "Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:",

  "datenschutz_description15": "Die Anonymisierungsfunktion von Google Analytics wird benutzt. Mit Google Inc. wurde ein Vertrag über die Auftragsverarbeitung geschlossen.",

  "datenschutz_title6": "Google Tag Manager / Google Ads",
  "datenschutz_description17": "Google Tag Manager ist eine Lösung, mit der Vermarkter Website-Tags über eine Oberfläche verwalten können. Das Tool Tag Manager selbst (das die Tags implementiert) ist eine Cookie lose Domain und erfasst keine personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.",
  "datenschutz_description18": "Für diese Daten gelten die abweichenden Datenschutzbestimmungen des Unternehmens Google. Weitere Informationen zu den Datenschutzrichtlinien von Google finden Sie unter:",
  "datenschutz_description19": "Mit Google Inc. wurde ein Vertrag über die Auftragsverarbeitung geschlossen.",

  "datenschutz_title7": "Paypal",
  "datenschutz_description20": "Bei Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal oder „Kauf auf Rechnung“ sowie Ratenzahlung über Paypal geben wir Ihre Zahlungsdaten im Rahmen der Zahlungsabwicklung an die PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (nachfolgend „PayPal“), weiter. PayPal behält sich für die Zahlungsmethoden Kreditkarte via PayPal, Lastschrift via PayPal oder „Kauf auf Rechnung“ via PayPal sowie der Ratenzahlung über Paypal die Durchführung einer Bonitätsauskunft vor. Das Ergebnis der Bonitätsprüfung in Bezug auf die statistische Zahlungsausfallwahrscheinlichkeit verwendet PayPal zum Zwecke der Entscheidung über die Bereitstellung der jeweiligen Zahlungsmethode. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte enthalten (sog. Score-Werte). Soweit Score-Werte in das Ergebnis der Bonitätsauskunft einfließen, haben diese ihre Grundlage in einem wissenschaftlich anerkannten mathematisch-statistischen Verfahren. In die Berechnung der Score-Werte fließen unter anderem Anschriftendaten ein.",
  "datenschutz_decsription20_1": "Weitere datenschutzrechtliche Informationen, unter anderem zu den verwendeten Auskunfteien, entnehmen Sie bitte der Datenschutzerklärung von PayPal:",

  "datenschutz_title8": "Sicherheit Ihrer Daten / SSL-Verschlüsselungen",
  "datenschutz_description21": "Im Einklang mit der gesetzlichen Regelung nach § 13 Abs. 7 TMG verwendet diese Seite eine SSL-Verschlüsselung, zu erkennen an einem Schloss-Symbol in der Adressleiste Ihres Browsers. Übermittelte Daten können bei aktivierter SSL-Verschlüsselung nicht von Dritten mitgelesen werden.",
  "datenschutz_description22": "In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.",
  "datenschutz_description23": "Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen (TOM), um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.",


  "datenschutz_title9": "Betroffenenrechte",

  "datenschutz_description24": "Sie können jederzeit und unentgeltlich Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten verlangen. Ihre Rechte umfassen dabei auch eine Bestätigung, Berichtigung, Beschränkung, Sperrung und Löschung von solchen Daten und die Zurverfügungstellung einer Kopie der Daten, in einer zur Übertragung geeigneten Form, sowie den Widerruf einer erteilten Einwilligung und den Widerspruch. Gesetzliche Aufbewahrungspflichten bleiben hiervon unberührt.",

  "datenschutz_description25": "Ihre Rechte ergeben sich dabei im Einzelnen insbesondere aus den folgenden Normen der DSGVO:",

  "datenschutz_list26": "<li>Artikel 7 Abs. 3 – Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</li><li>Artikel 12 – Transparente Information, Kommunikation und Modalitäten für die Ausübung der Rechte der betroffenen Person</li> <li>Artikel 13 – Informationspflicht bei Erhebung von personenbezogenen Daten bei der betroffenen Person</li> <li>Artikel 14 – Informationspflicht, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben wurden</li> <li>Artikel 15 – Auskunftsrecht der betroffenen Person, Recht auf Bestätigung und Zurverfügungstellung einer Kopie der personenbezogenen Daten</li> <li>Artikel 16 – Recht auf Berichtigung</li> <li>Artikel 17 – Recht auf Löschung („Recht auf Vergessenwerden“)</li><li>Artikel 18 – Recht auf Einschränkung der Verarbeitung</li><li>Artikel 19 – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogenDaten oder der Einschränkung der Verarbeitung</li><li>Artikel 20 – Recht auf Datenübertragbarkeit</li><li>Artikel 21 – Widerspruchsrecht</li><li>Artikel 22 – Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließliProfiling – beruhenden Entscheidung unterworfen zu werden</li><li>Artikel 77 – Recht auf Beschwerde bei einer Aufsichtsbehörde</li>",

  // AGB 

  "agb_title1": "1. ALLGEMEINES",
  "agb_description1": "Die Funfsterne betreibt im Internet u.a. unter der Domain Funfsterne.de eine über das Internet nutzbare Softwareanwendung (im Folgenden: Dienst), die Unternehmer bei ihren Tätigkeiten auf Handelsplattformen unterstützt. Anbieterdes Dienstes ist die Funfsterne, Potsdamer Straße 14410783 Berlin Deutschland (im Folgenden: Funfsterne). Diese Geschäftsbedingungen gelten für alle, auch zukünftige Geschäftsbeziehungen, zwischen dem Nutzer und Funfsterne . Abweichende, entgegenstehende oder ergänzendeAGB des Nutzers (auch z.B. Bezugsbedingungen, Einkaufsbedingungen) werden nicht Vertragsbestandteil, es sei denn, die Geltung ist bei Vertragsschluss ausdrücklich vereinbart. Jedem formularmäßigenHinweis auf Geschäftsbedingungen des Nutzers wird ausdrücklichwidersprochen.",

  "agb_title2": "2. VERTRAGSSCHLUSS",
  "agb_description2": "Gegenstand des Nutzungsvertrages ist die Bereitstellung des Dienstes zur Nutzung der Funktionalitäten an den Nutzer. Der jeweilige Leistungsumfang, nämlich insbesondere die technische Funktionalität und Art und Umfang der angebotenen Services ist abhängig von dem im Rahmen der Registrierung gewählten Tarif. Der jeweilige Funktionsumfang eines Tarifes ergibt sich aus der Leistungsbeschreibung. Daneben behält sich Funfsterne die Möglichkeit vor, Zusatzleistungen anzubieten, die über den gewählten Tarif hinausgehen. Die Kosten und der Umfang der jeweiligen Zusatzleistung ergeben sich ebenfalls aus der Leistungsbeschreibung und der jeweils gültigen Preisliste Für die Nutzung des Dienstes muss der Nutzer über einen Internetanschluss, ein internetfähiges Endgerät und einen aktuellen Internetbrowser verfügen, deren Kosten er selbst zu tragen hat. Kein Vertragsgegenstand ist die Erzielung eines bestimmten Verkaufserfolges auf Handelsplattformen. Funfsterne bietet lediglich die Nutzungsmöglichkeit für bestimmte Dienste, deren erfolgreicher Einsatz ist ausschließlich Aufgabe des Nutzers.",

  "agb_title3": "3. VERTRAGSSCHLUSS",
  "agb_description3": "Die Nutzung des Dienstes ist nur im Rahmen eines Nutzungsvertrages möglich. Der Abschluss eines Vertrags über die Nutzung des Dienstes ist nur Unternehmern, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen möglich. Der Nutzer ist verpflichtet, bei Abschluss eines Nutzungsvertrages nur wahrheitsgemäße Angaben zu seiner Person und dem Unternehmen zu machen und seine Daten stets aktuell zu halten. Funfsterne bietet den Abschluss kostenpflichtigen Nutzungsverträgen an. Mit Ausfüllen und Absenden des entsprechenden Formulars erklärt der Nutzer, einen Vertrag mit Funfsterne über die Nutzung der Dienste schließen zu wollen. Der Nutzer hat die Möglichkeit, vor Absenden des Formulars seine Angaben zu seiner Person bzw. seinem Unternehmen zu prüfen und ggf. durch Ändern der entsprechenden Felder zu korrigieren. Ferner kann er die von ihm ausgewählten Leistungen prüfen und durch Betätigen der entsprechenden Auswahlboxen Ändern. Durch Betätigen der entsprechenden Schaltfläche gibt der Nutzer ein verbindliches Angebot auf Abschluss eines Nutzungsvertrages ab. Funfsterne nimmt das Angebot durch Zusendung einer Bestellbestätigung, in der auch die Zugangsdaten zu den Diensten enthalten sind an. Für den Abschluss des Vertrages steht sowohl die deutsche als auch je nach genutzter Plattform die englische Sprache zur Verfügung. Der Vertragstext wird von Funfsterne gespeichert, ist dem Kunden jedoch nicht zugänglich.",

  "agb_title4": "4. BEREITSTELLUNG DES DIENSTES",
  "agb_description4": "Der Dienst wird dem Nutzer mit Freischaltung durch Funfsterne bereitgestellt. Übergabepunkt für die Bereitstellung des Dienstes ist der Router Ausgang des Rechenzentrums von Funfsterne. Funfsterne schuldet eine Verfügbarkeit des Dienstes am Übergabepunkt von 97% im Jahresmittel.",

  "agb_title5": "5. NUTZUNGSBEFUGNIS, RECHTE",
  "agb_description5": "Funfsterne äumt dem Nutzer das einfache, nicht unter lizenzierbare und nicht übertragbare Recht ein, den Dienst während der Laufzeit des Vertrages für eigene Unternehmenszwecke zu nutzen. Nicht eingeräumte Rechte stehen dem Nutzer nicht zu. Der Nutzer ist insbesondere nicht berechtigt, Dritten den Dienst zur Nutzung zu überlassen oder mittels des Dienstes Leistungen für Dritte zu erbringen. Hiervon ausgenommen sind Dienstleiter, die unmittelbar für den Kunden tätig werden. Sofern und soweit während der Laufzeit des Nutzungsvertrages auf dem Server von Funfsterne eine Datenbank, Datenbanken oder ein Datenbankwerk oder Datenbankwerke entstehen, stehen alle Rechte hieran dem Nutzer zu. Der Nutzer bleibt auch nach Vertragsende Eigentümer der Datenbanken bzw. Datenbankwerke.",

  "agb_title6": "6. ENTGELT, ZAHLUNG",
  "agb_description6": "Soweit die Nutzung des Dienstes kostenpflichtig ist, richten sich die Kosten nach den Angaben in der jeweiligen Preisliste. Alle Preise gelten zzgl. der gesetzlichen Mehrwertsteuer. Alle Zahlungen sind entsprechend der jeweiligen Leistungsbeschreibung zu entrichten und können mittels der angebotenen Zahlungsverfahren erbracht werden. Soweit die Parteien die Zahlungsart „Lastschrift” vereinbaren, erteilt der Nutzer Funfsterne zum Einzug der jeweils fälligen Forderung, auch für wiederkehrende Zahlungen und Verbindlichkeiten in wechselnden Höhen ein SEPA-Basis-Mandat / SEPA-Firmen-Mandat. Die Frist für die Vorabankündigung (Pre-Notification) wird, soweit zulässig, auf 1 Tag verkürzt. Der Nutzer sichert zu, für die Deckung des Kontos zu sorgen. Kosten, die aufgrund von Nichteinlösung oder Rückbuchung eines Zahlungsauftrags entstehen, gehen zu Lasten des Kunden, solange die Nichteinlösung oder die Rückbuchung nicht durch Funfsterne wurde. Zusätzlich erhebt Funfsterne eine Bearbeitungsgebühr in Höhe von 10€ netto. Die Rechnungsstellung durch Funfsterne erfolgt in elektronischer Form. Eine Übersendung findet per E-Mail statt. Daneben kann Funfsterne dem Nutzer die Möglichkeit einräumen, Rechnungsdokumente im Nutzerkonto selbst in Dateiform abzurufen. Wünscht der Nutzer zusätzlich einen Versand auf dem Postweg, ist Funfsterne berechtigt, hierfür einen Pauschalbetrag gemäß der Preisliste zu verlangen. Funfsterne agiert als Vermittlungsplattform zwischen Unternehmer und Tester. Im Rahmen dieser Funktion verwaltet Funfsterne das auf der Plattform aufgeladene Guthaben des Unternehmers, um dieses in Managed Kampagnen an die Tester auszuzahlen. Auf Funfsterne eingezahltes Guthaben muss durch Dienstleistungen des Tools (Testerauszahlungen, Kauf von Managed/Fully Managed Zuschlägen) aufgebraucht werden. Eine Auszahlung des Funfsterne Guthabens in Bar oder die Transferierung auf ein Bankkonto ist nicht möglich.",

  "agb_title7": "7. PFLICHTEN UND OBLIEGENHEITEN DES NUTZERS",
  "agb_description7": "Der Nutzer wählt zum Zwecke der Nutzung des Dienstes Zugangsdaten. Der Nutzer ist verpflichtet, diese Zugangsdaten geheim zu halten und Funfsterne über den Verlust oder die unbefugte Nutzung der Zugangsdaten durch Dritte unverzüglich zu unterrichten. Dem Nutzer ist es nicht gestattet, Zugangsdaten Dritten zur Verfügung zu stellen. Funfsterne ist berechtigt, Zugangsdaten zu sperren, falls ein Verdacht einer unbefugten Nutzung oder eines Missbrauchs der Daten vorliegt. Der Nutzer darf den Dienst nur für eigene unternehmerische Zwecke nutzen. Der Nutzer verpflichtet sich dazu, weder die Dienste zur Bewerbung von mit Funfsterne konkurrierenden Angeboten zu nutzen noch Daten bzw. Kontaktdaten Dritter innerhalb der Dienste zu sammeln und mit diesen Dritten außerhalb der Dienste in Kontakt oder vertragliche Beziehungen zu treten. Soweit der Nutzer feststellt, dass der Dienst nicht oder nicht ordnungsgemäß arbeitet, ist er verpflichtet, Funfsterne hiervon in Textform unverzüglich in Kenntnis zu setzen.",

  "agb_title8": "8. DATENSCHUTZ",
  "agb_description8": "Die Parteien werden die jeweils anwendbaren, insb. die in Deutschland gültigen datenschutzrechtlichen Bestimmungen beachten. Funfsterne ist berechtigt, die Daten der Nutzer in anonymer Form statistisch auszuwerten und für eigene Zwecke, auch für Werbezwecke zu verwenden. Dies dient insbesondere der Weiterentwicklung der Dienste.",

  "agb_title9": "9. HAFTUNG",
  "agb_description9": "Funfsterne haftet dem Nutzer bei Vorsatz oder grober Fahrlässigkeit für alle selbst sowie durch gesetzliche Vertreter oder Erfüllungsgehilfen verursachten Schäden unbeschränkt. Bei leichter Fahrlässigkeit haftet Funfsterne im Fall der Verletzung des Lebens, des Körpers oder der Gesundheit unbeschränkt. Im Übrigen haftet Funfsterne nur, soweit eine wesentliche Vertragspflicht verletzt wurde. Wesentliche Vertragspflichten sind solche Pflichten, die für die Erreichung des Vertragsziels von besonderer Bedeutung sind, ebenso diejenigen Pflichten, die im Fall einer schuldhaften Verletzung dazu führen können, dass die Erreichung des Vertragszwecks gefährdet wird. In diesen Fällen ist die Haftung auf den Ersatz des vorhersehbaren, typischerweise eintretenden Schadens beschränkt. Die verschuldensunabhängige Haftung von Funfsterne auf Schadensersatz nach §536a BGB für bei Vertragsschluss vorhandene Mängel wird ausgeschlossen, die vorstehenden Absätze bleiben unberührt. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.",


  "agb_title10": "10. LAUFZEIT, KÜNDIGUNG",
  "agb_description10": "Die Laufzeit des Vertrages über eine kostenpflichtige Nutzung des Dienstes beginnt mit Freischaltung durch Funfsterne und läuft monatsweise auf unbestimmte Zeit. Wählt der Nutzer ein abweichendes Zahlungsintervall, so verlängert sich die Vertragslaufzeit auf das gewählte Zahlungsintervall. Die Laufzeit eines Vertrages über eine kostenpflichtige Nutzung des Dienstes verlängert sich automatisch um die jeweilige Vertragslaufzeit, wenn er nicht fristgerecht gekündigt wird. Überschreiten genutzte Services, insbesondere Zusatzleistungen in ihrer Laufzeit die Laufzeit des Vertrages, so kann der Vertrag nicht vor Ablauf der Laufzeit der Zusatzleistungen gekündigt werden. Die Kündigung, auch die von Funfsterne, bedarf zumindest der Textform; hierfür gilt zudem eine Frist von zwei Wochen zum jeweiligen Laufzeitende. Unberührt bleibt die Möglichkeit zur außerordentlichen Kündigung aus wichtigem Grund. Diese Kündigung bedarf ebenfalls der Textform. Stellt der Nutzer die Nutzung des Dienstes vor Ablauf der Kündigungsfrist einseitig ein, so bleibt seine Pflicht zur Entrichtung der Vergütung hiervon unberührt. Ist der Kunde mit der Zahlung der monatlichen Kosten oder mit sonstigen Zahlungsverpflichtungen nicht nur geringfügig im Zahlungsverzug, so kann Funfsterne bei Fortdauer der Zahlungsverpflichtung die Nutzungsberechtigung der Dienste bis zur vollständigen Nacherfüllung des Zahlungsverzuges entziehen und/oder die Inanspruchnahme weiterer Leistungen so lange verweigern. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt neben dem Recht zum Entzug der Nutzungsberechtigung unberührt.",

  "agb_title11": "11. ÄNDERUNGEN DIESER AGB, ÜBERTRAGUNG VON RECHTEN",
  "agb_description11": "Funfsterne hat das Recht, die Bestimmungen bezüglich der zu erbringenden Leistung nach billigem Ermessen in Abwägung der technischen Erfordernisse und Marktgegebenheiten zu ändern, soweit dies für den Nutzer zumutbar ist. Änderungen dieser Allgemeinen Geschäftsbedingungen werden im Dienst veröffentlicht. Über Änderungen der AGB, die nicht unter Abs. 1 fallen, wird der Nutzer in Textform informiert. Die Änderungen werden wirksam, sofern der Nutzer den jeweiligen Änderungen nicht spätestens 14 Tage nach Zugang der Änderungsmitteilung in Textform widerspricht. Auf die Bedeutung seines Schweigens wird der Nutzer zusammen mit der Änderungsmitteilung informiert. Widerspricht der Nutzer der Änderung, ist Funfsterne berechtigt, den Vertrag ordentlich zum Laufzeitende zu kündigen. Macht Funfsterne von diesem Kündigungsrecht keinen Gebrauch, wird der Vertrag zu den bis dahin geltende Bedingungen fortgesetzt.",

  "agb_title12": "12. SCHLUSSBESTIMMUNGEN",
  "agb_description12": "Es gilt das Recht der Bundesrepublik Deutschland. Die Bestimmungen des UN-Kaufrechts finden keine Anwendung. Ist der Nutzer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag der Geschäftssitz von Funfsterne. Dasselbe gilt, wenn der Nutzer keinen allgemeinen Gerichtsstand in Deutschland hat oder Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt sind.",




}

