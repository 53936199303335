import '@/styles/globals.css'
import "swiper/css";
import 'flowbite';
import Head from 'next/head';
import Layout from '../components/static/_layout'
import { Inter } from 'next/font/google'
import { useState } from 'react'
import React from "react";
export const MyContext = React.createContext();

const inter = Inter({ subsets: ['latin'] })
const App = ({ Component, pageProps }) => {

const [globalValue, setGlobalValue] = useState(1);
const [isSelected, setIsSelected] = useState(false);
const [priceLöschen, setpriceLöschen] = useState(200);
const [selectedValue, setSelectedValue] = useState(0);


  return (
    <MyContext.Provider value={{ globalValue, setGlobalValue, isSelected, setIsSelected ,priceLöschen , setpriceLöschen, selectedValue, setSelectedValue  }}>
        <Layout className={inter.className}>
            <style jsx global>{`
            html {
            font-family: ${inter.style.fontFamily};
            }
        `}</style>

        <Head>

            <link rel="icon" type="image/x-icon" href="/images/global/favicon-funfsterne.ico" />
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://avis123.fr/" />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://avis123.fr/" />

            </Head>
                <Component {...pageProps} />
        </Layout>
    </MyContext.Provider>
  )
}




export default App



